import api from '../../utils/api'

// 提交验证码
export async function submit_code (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {


  //       }
  //     })
  //   }, 300)
  // })
  return api.post(`/api/v1/mp/luohu/users/white_list`, payload)
}







