<template>
  <div class="loginDiv">
    <g-loading :loading="loading" />
    <van-form
      class="login-form"
      label-width="50"
      label-align="right"
      @submit="submit_form">
      <div class="text"> 小会体验用户申请</div>
      <div class="next-text">您好，欢迎来到小会！</div>
     <van-field
        v-model="data.code"
        center
        name="code"
        clearable
        label=""
        placeholder="邀请码"
        :rules="[{ required: true, message: '请填写邀请码' }]"
      >
      </van-field>
      <div style="margin-top: 40px;">
        <van-button round block type="info" native-type="submit">提交</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { submit_code } from './service.js'

export default {
  data () {
    return {
      data: {
        code: ''
      },
      loading: false
    }
  },
  components: {
  },
  computed: {
  },
  methods: {
    async submit_form () {
      this.loading = true;
      let code = this.data.code.trim()
      try {
        await submit_code({code})
        this.$notify({ type: "success", message: "提交成功" })
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.loginDiv{
  height: 100%;
  display: flex;
  justify-content: center;
  padding-top: 50px;
}
.text {
  font-size: 28px;
  font-weight: 500;
  line-height: 40px;
  color: #242938;
}
.next-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 40px;
  color: #999999;
  padding-bottom: 60px;
}
>>>.van-cell {
  padding: 10px 0;
}
>>>.van-button--info  {
  height: 44px;
  background: linear-gradient(90deg, #3EC3FC 0%, #01B6FD 36%, #2D58F0 100%);
  box-shadow: 0px 4px 8px rgba(0, 136, 255, 0.45);
  border-radius: 24px;
}

</style>
